// Prod-properties (InCcaas)
const LOGIN_URL = 'https://api.cloudagent.ozonetel.com/auth/login';
const BASE_URL = 'https://api.cloudagent.ozonetel.com/dashboardApi';
const BASE_API_URL = 'https://api.cloudagent.ozonetel.com/reportApi/endpoint';
const LIVE_BASE_URL = 'https://api.cloudagent.ozonetel.com/liveReportApi';
const ADMIN_BASE_URL = 'https://api.cloudagent.ozonetel.com/ca-admin-Api/CloudAgentAPI';
const AGENT_LOGIN_URL = 'https://agent.cloudagent.ozonetel.com/login'
const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
const IVR_FLOW_URL = 'https://ivr.cloudagent.ozonetel.com/ivrdd';
const LOGIN_PAGE_BACKGROUND_IMG_URL = "https://s3.ap-south-1.amazonaws.com/ca.cdn/";
const UPDATE_PASSWORD_URL = 'https://api.cloudagent.ozonetel.com/updatepassword';
const FORGOT_PASSWORD_URL = 'https://api.cloudagent.ozonetel.com/resetpassword';
const APP_URL = 'http://kookoo_apps/DDParser/v1/index.php?tag_id=start&flow_id=';
const APXOR_KEY = '1ce43d78-05ff-420a-91af-8a063a8816d5';
const LOCATION = 'IN';
const DateFormatCalenderInHistoricReports = "DD-MM-YYYY";
const IMAGE_UPLOAD_URL = 'https://in1-ccaas-api.ozonetel.com'; 
const DateFormat = "DD-MM-yyyy";
const DateTimeFormat = "DD-MM-yyyy HH:mm:ss"
const SystemDateFormat = "yyyy-MM-DD";
const SC_RECORDING_URL = 'https://in-ccaas-sc-recording.ozonetel.com';
const OAUTH2_VALIDATE_URL = 'https://api.cloudagent.ozonetel.com/auth/oauth2/validate';
// export { LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, LOGIN_PAGE_BACKGROUND_IMG_URL, IVR_FLOW_URL, DateFormat, DateTimeFormat,UPDATE_PASSWORD_URL,FORGOT_PASSWORD_URL,APP_URL  }
export { LOGIN_PAGE_BACKGROUND_IMG_URL, LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DateFormat, SystemDateFormat, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL, APP_URL, IMAGE_UPLOAD_URL, APXOR_KEY, LOCATION, DateFormatCalenderInHistoricReports, SC_RECORDING_URL, OAUTH2_VALIDATE_URL }

